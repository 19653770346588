import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import PageLayout from '../../components/PageLayout';
import * as styles from './index.module.scss';

const AboutPage = () => {
    return (
        <PageLayout>
            <Link to='/'>
                <img src='/skissan.svg' alt='Skissan' />
            </Link>

            <h1>About</h1>
            <Helmet>
                <title>About - Skissan</title>
            </Helmet>
            <GameContainer>
                <div className={styles.about}>
                    <h3>History</h3>
                    <p>
                        Skissan was originally created back in the year 2007 as
                        Skissa, but it has now been completely re-written using
                        the latest technology available and re-named to Skissan.
                    </p>
                    <p>Here are some screenshots from the past.</p>
                    <div>
                        <img src='/history1.jpg' alt='History' />
                        <img src='/history2.jpg' alt='History' />
                        <img src='/history3.jpg' alt='History' />
                        <img src='/history4.jpg' alt='History' />
                    </div>
                </div>
                <h3>Skissan</h3>
                <p>
                    Skissan currently only support English and Icelandic word
                    lists. Another game mode is being worked on.
                </p>
                <h3>Contact</h3>
                <p>
                    You can contact us through our{' '}
                    <a
                        href='https://slidesome.com/contact'
                        target='_blank'
                        rel='noreferrer'
                    >
                        contact form
                    </a>
                    .
                </p>
                <h3>Community</h3>
                <p>
                    We have a{' '}
                    <a href='https://www.facebook.com/Skissan/' target='_blank'>
                        Facebook Page
                    </a>{' '}
                    and a{' '}
                    <a href='https://discord.gg/tfYzagJ8tG' target='_blank'>
                        Discord community
                    </a>
                    .
                </p>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

export default AboutPage;
